/**
 * entry.js
 *
 * This is the first file loaded. It sets up the Renderer,
 * Scene and Camera. It also starts the render loop and
 * handles window resizes.
 *
 */
//CSS
import "./public/scss/main.scss";
//3D
import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  Clock,
  Raycaster,
  LineBasicMaterial,
  Line,
  BufferGeometry,
  Vector3,
  Fog,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";

import ScrollTrigger from "gsap/src/ScrollTrigger.js";
import SeedScene from "./objects/Scene.js";
import "./public/js/app.js";
function initThreejs() {
  
  const listMenu = document.querySelectorAll(".menu-right .menu-item");
  const clock = new Clock();
  
  const scene = new Scene();
  scene.fog = new Fog(0x000000, 0.1, 30);
  const camera = new PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    1,
    10000
  );
  const renderer = new WebGLRenderer({
    antialias: true,
    shadowMapEnabled: true,
    alpha: true,
  });
  const seedScene = new SeedScene(clock);

  //PANDA
  const container = document.body.appendChild(renderer.domElement);
  let windowHalfX = window.innerWidth / 2;

  //Rotation variable
  let targetRotation = 9;
  let targetRotationOnPointerDown = 0;
  let pointerX = 0;
  let pointerXOnPointerDown = 0;

  //Init var
  let isScrolled = false;



  // scene
  scene.add(seedScene);


  seedScene.receiveShadow = true;
  // Between 0 and 1
  scene.children[0].children[1].rotation.y += -5;

  //Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  // controls.rotateSpeed = 0.05;
  controls.enableDamping = true;
  controls.enablePan = false;
  // controls.dampingFactor = 0.05;
  controls.enableZoom = false;
  controls.maxPolarAngle = 1.15;
  controls.minPolarAngle = 1.15;

  // camera.position.set(
  //   -14.816423528367027,
  //   6.1543452630449975,
  //   3.900416631301264
  // );
  camera.position.set(
    2.8576498240943153,
    6.744567070260116,
    14.797306315815057
  );

  gsap.registerPlugin(ScrollTrigger);
  gsap.timeline({
    scrollTrigger: {
      // start: 0,
      // end: "max",
      scrub: true,
    },
  });

  let scrollRotationSpeed = 0.005;
  let cameraAngle = 0;
  window.addEventListener(
    "wheel",
    (event) => {
      // Prévenir le comportement de défilement par défaut
      event.preventDefault();

      isScrolled = true;

      // Inversez la valeur de deltaY
      const invertedDeltaY = event.deltaY * -1;

      const deltaRotation = invertedDeltaY * scrollRotationSpeed;

      const maxDeltaRotation = 0.2;
      const clampedDeltaRotation = Math.max(
        -maxDeltaRotation,
        Math.min(maxDeltaRotation, deltaRotation)
      );

      cameraAngle += clampedDeltaRotation;

      const radius = 15;
      const newX = radius * Math.sin(cameraAngle);
      const newZ = radius * Math.cos(cameraAngle);
      gsap.to(camera.position, { duration: 2, x: newX, z: newZ });

      // camera.position.set(newX, cameraPositionY, newZ);
    },
    { passive: false }
  );

  renderer.autoClear = false;

  // renderer
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setClearColor(0xcccccc, 1);
  renderer.shadowMap.enabled = true;

  // Events
  container.style.touchAction = "none";
  container.addEventListener("pointerdown", onPointerDown);
  renderer.setClearColor(0x000000, 0);

  // Créez un rayon à partir de la position de la caméra et de la direction de la caméra
  const raycaster = new Raycaster();
  const cameraDirection = new Vector3();
  const rayGeometry = new BufferGeometry().setFromPoints([
    new Vector3(), // Origine du rayon (position de la caméra)
    new Vector3(0, 0, -10), // Point vers lequel le rayon est lancé (ajustez selon vos besoins)
  ]);
  const rayMaterial = new LineBasicMaterial({ color: 0xff0000 }); // Couleur rouge

  // Créez une ligne à partir de la géométrie et du matériau
  const rayLine = new Line(rayGeometry, rayMaterial);

  // Fonction pour vérifier ce que la caméra vise
  function checkCameraTarget() {
    // Mettez à jour la direction du rayon en fonction de la caméra
    camera.getWorldDirection(cameraDirection);

    // Mettez à jour la géométrie de la ligne pour qu'elle parte de la position de la caméra
    rayGeometry.attributes.position.setXYZ(
      0,
      camera.position.x,
      camera.position.y,
      camera.position.z
    );

    // Mettez à jour la direction vers laquelle le rayon est lancé
    const targetPosition = new Vector3(
      camera.position.x + cameraDirection.x * 20, // Ajustez la longueur du rayon ici
      camera.position.y + cameraDirection.y * 20,
      camera.position.z + cameraDirection.z * 20
    );
    rayGeometry.attributes.position.setXYZ(
      1,
      targetPosition.x,
      targetPosition.y,
      targetPosition.z
    );

    rayGeometry.attributes.position.needsUpdate = true;

    // Créez le rayon à partir de la position de la caméra et de la direction
    raycaster.set(camera.position, cameraDirection);

    // Recherchez les objets qui intersectent le rayon
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (intersects.length > 0) {
      // L'objet le plus proche que la caméra vise
      const targetObject = intersects[0].object;
      if (targetObject.name == "cubeForAbout") {
        rotationDisplayPopup("aboutme");
        // listMenu.forEach((link) => {link.dataset.href=='about'?link.classList.add("glow"):link.classList.remove("glow")});
      } else if (targetObject.name == "cubeForContact") {
        rotationDisplayPopup("contact");
        // listMenu.forEach((link) => {link.dataset.href=='contact'?link.classList.add("glow"):link.classList.remove("glow")});
      } else if (targetObject.name == "cubeForProjects") {
        rotationDisplayPopup("projects");
        // listMenu.forEach((link) => {link.dataset.href=='projects'?link.classList.add("glow"):link.classList.remove("glow")});
      } else {
        rotationDisplayPopup("home");
      }
    }
  }

  // Appelez la fonction checkCameraTarget lorsque vous avez besoin de vérifier la cible de la caméra

  // render loop
  const onAnimationFrameHandler = (timeStamp) => {
    controls.update();

    const cameraRotationY = targetRotation;
    let arrondi = cameraRotationY * 10000;
    arrondi = Math.round(arrondi);
    arrondi = arrondi / 10000;
    arrondi = arrondi * -1.15;
    scene.children[0].children[1].rotation.y = arrondi;

    seedScene.updateRotation(timeStamp);
    seedScene.update(clock.getDelta());
    // Rendre la scène avec l'objet bloomé

    renderer.render(scene, camera);

    checkCameraTarget();
    window.requestAnimationFrame(onAnimationFrameHandler);
  };
  onAnimationFrameHandler();

  // resize
  const windowResizeHanlder = () => {
    const { innerHeight, innerWidth } = window;
    console.log(innerWidth);
    renderer.setSize(innerWidth, innerHeight);
    camera.aspect = innerWidth / innerHeight;
    camera.updateProjectionMatrix();
    controls.update();
  };
  windowResizeHanlder();
  window.addEventListener("resize", windowResizeHanlder);

  function startAnimation() {
    seedScene.startAnimation();
  }
  function stopAnimation() {
    seedScene.stopAnimation();
  }

  function onPointerDown(event) {
    if (event.isPrimary === false) return;

    pointerXOnPointerDown = event.clientX - windowHalfX;
    targetRotationOnPointerDown = targetRotation;

    document.addEventListener("pointermove", onPointerMove);
    document.addEventListener("pointerup", onPointerUp);
  }

  function onPointerMove(event) {
    if (event.isPrimary === false) return;

    pointerX = event.clientX - windowHalfX;
    targetRotation =
      targetRotationOnPointerDown + (pointerX - pointerXOnPointerDown) * 0.0003;

    //Contact
  }

  function onPointerUp(event) {
    if (event.isPrimary === false) return;

    document.removeEventListener("pointermove", onPointerMove);
    document.removeEventListener("pointerup", onPointerUp);
  }

  // dom
  document.body.style.margin = 0;
  document.body.appendChild(renderer.domElement);
}

initThreejs();
