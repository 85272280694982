import { Group, DirectionalLight, AmbientLight,PointLight } from 'three';

export default class BasicLights extends Group {
  constructor(...args) {
    super(...args);

    // const dir = new DirectionalLight(0xffffff,0.3);
    // const ambient = new AmbientLight(0x212121,5);

    const totem1 = new PointLight(0xFEA9A9, 3.32, 8.08);
    totem1.position.set(0, 3.140, -1.461);
    totem1.decay = 1.06;

    const totem2 = new PointLight(0xFEA9A9, 3.32, 8.08);
    totem2.position.set(0, 3.140, 1.311);
    totem2.decay = 1.06;

    const aboutme = new PointLight(0xE2AA6A, 4, 10);
    aboutme.position.set(-2.321, 1.531, 2.494);
    aboutme.decay = 1.62;

    const delorean = new PointLight(0xFF9500, 2.32, 2.74);
    delorean.position.set(2.948, 0.559, 2.889);
    delorean.decay = 0.98;

    const contact1 = new PointLight(0xD388DD, 1.00, 0);
    contact1.position.set(3.223, 1.438, -1.497);
    contact1.decay = 2;

    const contact2 = new PointLight(0xD388DD, 1.00, 0);
    contact2.position.set(1.733, 1.438, -3.294);
    contact2.decay = 2;

    // dir.intensity = 4;

    // dir.castShadow = true;
    // dir.shadow.mapSize.width = 200; // Largeur de la carte d'ombres
    // dir.shadow.mapSize.height = 200; // Hauteur de la carte d'ombres
    // dir.shadow.camera.near = 0.1; //.1 Plan rapproché de la caméra d'ombres
    // dir.shadow.camera.far = 400;  //50 Plan éloigné de la caméra d'ombres
    // dir.shadow.bias = 0.002; //0.0001

    // const helper = new RectAreaLightHelper( rectLight8 );

    // const spotLight = new SpotLight( 0xD2CCFF, 4, 0, 0.2, 1 );
    // spotLight.position.set( 0.7, 11, -2 );
    // const spotLight2 = new SpotLight( 0xD2CCFF, 4, 0, 0.2, 1 );
    // spotLight2.position.set( 0.7, 11, 2 );





    // this.add(ambient,dir);
    this.add(totem1,totem2,aboutme,delorean,contact1,contact2)
    // this.add(dir,ambient)

  }
}
