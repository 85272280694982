import {
  AnimationMixer,
  AnimationAction,
  Clock,
  Vector3,
  BoxGeometry,
  MeshBasicMaterial,
  Mesh,
} from "three";
import { Group } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import MODEL from "./panda_skate_animate_4.glb";

export default class Panda extends Group {
  constructor() {
    const loader = new GLTFLoader();
    super();

    this.name = "panda";
    this.size = 0.0014;

    //Animation
    this.mixer = null;
    this.animationAction = null;

    //Rotation
    this.rotationTarget = 0;
    this.axeCenter = new Vector3(0, 0, 0);

    loader.load(MODEL, (gltf) => {
      gltf.scene.position.x = -1.5;
      gltf.scene.position.y = 0.2;
      gltf.scene.position.z = -5.5;

      gltf.scene.rotation.x = -0.1;
      gltf.scene.rotation.y = -1.5;
      gltf.scene.rotation.z = 0;

      gltf.scene.scale.x = this.size;
      gltf.scene.scale.y = this.size;
      gltf.scene.scale.z = this.size;
      const model = gltf.scene;
      this.add(model);

      // Créez une géométrie et un matériau pour le nouvel objet
      const geometry = new BoxGeometry(8, 8, 0.1); // Exemple : utilisez une boîte comme géométrie
      const material = new MeshBasicMaterial({ color: 0xff0000 ,opacity:0,transparent:true}); // Exemple : utilisez un matériau rouge
      const material2 = new MeshBasicMaterial({ color: 0xADFF2F,opacity:0,transparent:true }); // Exemple : utilisez un matériau rouge
      const material3 = new MeshBasicMaterial({ color: 0x0000ff,opacity:0,transparent:true}); // Exemple : utilisez un matériau rouge

      // Créez le nouvel objet en utilisant la géométrie et le matériau
      const newObjAbout = new Mesh(geometry, material);
      const newObjContact = new Mesh(geometry, material2);
      const newObjProjects = new Mesh(geometry, material3);
      // Positionnez le nouvel objet devant l'objet existant
      newObjAbout.position.copy(model.position); // Position de l'objet existant
      newObjAbout.position.x += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjAbout.position.z += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjAbout.position.y += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjAbout.name = "cubeForAbout";
      // Ajoutez le nouvel objet à la scène

      newObjContact.position.copy(model.position); // Position de l'objet existant
      newObjContact.position.x += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjContact.position.z += 10; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjContact.position.y += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjContact.name = "cubeForContact";

      newObjProjects.position.copy(model.position); // Position de l'objet existant
      newObjProjects.position.x += 6; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjProjects.position.z += 5; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjProjects.position.y += 1; // Ajustez la position en fonction de la distance souhaitée devant l'objet existant
      newObjProjects.rotateY(1.8);
      newObjProjects.name = "cubeForProjects";


      this.add(newObjAbout, newObjContact,newObjProjects);

      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          child.castShadow = true;
        }
      });
      // this.mixer = new AnimationMixer(model);

      // this.animationAction = this.mixer.clipAction(gltf.animations[0]).play() ;

      // this.animationAction.stop();
    });
  }
  startAnimation() {
    if (this.animationAction) {
      this.animationAction.play();
    }
  }
  stopAnimation() {
    if (this.animationAction) {
      this.animationAction.stop();
    }
  }
  update(deltaTime) {
    if (this.mixer) {
      this.mixer.update(deltaTime);
    }
  }
}
