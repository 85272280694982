import { Group } from 'three';
import Land from './Land/Land.js';
import Panda from './Panda/Panda.js';
import BasicLights from './Lights.js';

export default class SeedScene extends Group {
  constructor() {
    super();
    const land = new Land();
    const panda = new Panda();
    const lights = new BasicLights();
    this.panda= panda;
    this.land = land;
    this.add(land,panda,lights);
  }

  startAnimation(){
    this.land.startAnimation();
    // this.panda.startAnimation();
  }
  stopAnimation(){
    this.land.stopAnimation();
    // this.panda.stopAnimation();
  }

  updateRotation(timeStamp){
    if(this.land.children[0]){
      this.land.children[0].children[59].children[1].rotation.y = timeStamp/1000
    }
  }
  update(deltaTime) {
    this.land.update(deltaTime);
  }
}