import { Group, Vector3 } from "three";
import { AnimationMixer, AnimationAction, Clock } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import MODEL from "./plateforme_final_roof_shadows.glb";

export default class Land extends Group {
  constructor() {
    const loader = new GLTFLoader();

    super();

    this.name = "land";
    //Animation
    this.mixer = null;
    this.animationAction = null;

    const loadingBar = document.getElementById("loading-bar");
    const loadingPercent = document.getElementById("progress");
    const loadingPercentNumber = document.getElementById("progress-text");


    // setTimeout(() => {
      loader.load(
        MODEL,
        (gltf) => {
          this.add(gltf.scene);
          const model = gltf.scene;
          this.add(model);
          this.mixer = new AnimationMixer(model);
          this.animationAction = this.mixer.clipAction(gltf.animations[2]).play();
          gltf.scene.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
            }
          })
        },
        (xhr) => {
          const percentage = (xhr.loaded / 14304704) * 100;
          loadingPercent.style.width = percentage + "%";
          if(percentage > 99){
            loadingPercentNumber.innerHTML = 99 + "%";
          }else{
            loadingPercentNumber.innerHTML = Math.round(percentage) + "%";
          }
          
          if (percentage => 100) {
            setTimeout(() => {
              loadingBar.classList.add("disabled");
            }, 2000);
          }
        },
        (error) => {
          console.error("Erreur lors du chargement du modèle", error);
        }
      );
    // }, 10000);
  }

  startAnimation() {
    if (this.animationAction) {
      this.animationAction.play();

    }
  }
  stopAnimation() {
    if (this.animationAction) {
      this.animationAction.stop();
    }
  }

  update(deltaTime) {
    if (this.mixer) {
      this.mixer.update(deltaTime);
    }
  }
}
